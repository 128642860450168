import { React } from "react"
import { useParams } from "react-router-dom";
import GamesOverview from "./components/Games/GamesOverview.js";
import SingleGameNavigation from "./components/Games/SingleGameNavigation.js";
import SingleGame from "./components/Games/SingleGamePlaying.js";
import SingleGameDashboard from "./components/Games/SingleGameDashboard.js";
import SingleGameSettings from "./components/Games/SingleGameSettings.js";

const Games = (props) => {
    const { loggedIn } = props,
        { gameid, page } = useParams();

    if( loggedIn === undefined ){
    } else if( gameid !== undefined ){
        if( page === "dashboard" ){
            return <div className="mainContainer">
                <SingleGameDashboard/>
                <SingleGameNavigation gameid={gameid} page="dashboard"/>
            </div>;
        } else if (page === "settings"){
            return <div className="mainContainer">
                <SingleGameSettings/>
                <SingleGameNavigation gameid={gameid} page="settings"/>
            </div>;
        } else {
            return <div className="mainContainer">
                <SingleGame/>
                <SingleGameNavigation gameid={gameid} page="playing"/>
            </div>;
        }
    } else {
        return <GamesOverview />;
    }
}

export default Games

