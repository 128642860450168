import { React, useState, useEffect } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
// import fontawesome from '@fortawesome/fontawesome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


const Navigation = (props) => {
    const navigate = useNavigate();
    const backToGames = (e) => {
        e.preventDefault();
        navigate('/games')
    }
    return <>
        <div className="headerSpacer">
            <button onClick={(e)=>{backToGames(e)}}>
                <span className="backIcon"></span>
            </button>
        </div>
    </>
}

export default Navigation;