import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './home';
import Login from './login';
import Games from './games';
import Navigation from './components/Navigation';
import './App.css';
import './style/style.scss';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import _uniqueId from 'lodash/uniqueId';


function App() {
  const [loggedIn, setLoggedIn] = useState(false)
  const [email, setEmail] = useState("");
  
  useEffect(() => {
    // Fetch the user email and token from local storage
    const user = JSON.parse(localStorage.getItem("user"))

    // If the token/email does not exist, mark the user as logged out
    if (!user || !user.token) {
      setLoggedIn(false)
      return
    }

    // If the token exists, verify it with the auth server to see if it is valid
    fetch(`${process.env.REACT_APP_API_URL}/api/verify`, {
            method: "POST",
            headers: {
                'jwt-token': user.token
              }
        })
        .then(r => r.json())
        .then(r => {
            setLoggedIn('success' === r.message)
            setEmail(user.email || "")
        })
  }, [])

  return (
    <div className="App">
      <script src="https://kit.fontawesome.com/683a3489c7.js" crossOrigin="anonymous"></script>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home email={email} loggedIn={loggedIn} setLoggedIn={setLoggedIn}/>} />
          <Route path="/games/:gameid"        element={<><Navigation/><Games email={email} loggedIn={loggedIn} setLoggedIn={setLoggedIn}/></>} />
          <Route path="/games/:gameid/:page" element={<><Navigation/><Games email={email} loggedIn={loggedIn} setLoggedIn={setLoggedIn}/></>} />
          <Route path="/games" element={<Games email={email} loggedIn={loggedIn} setLoggedIn={setLoggedIn}/>} />
          <Route path="/login" element={<Login loggedIn={loggedIn} setLoggedIn={setLoggedIn} setEmail={setEmail} />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
