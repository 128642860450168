import { React, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const SingleGameSettings = (props) => {
    const { gameid } = useParams();
    const [players, setPlayers] = useState([]),
        [newPlayer, setNewPlayer] = useState(''),
        [currentGame, setCurrentGame] = useState(''),
        [gameOngoing, setGameOngoing] = useState(false)

    useEffect(() => {
        getPlayers();
    }, []);

    const getPlayers = async () => {
        const user = JSON.parse(localStorage.getItem("user"))
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/api/game/${gameid}`, {
                headers: {
                    'jwt-token': user.token
                }
            }
            );
            let game = await response.json();
            console.log(game)
            setPlayers(game.players);
            setCurrentGame(game);
            if ([...game.rounds].reverse()[0].heats.length > 0) {
                setGameOngoing(true)
            } else {
                setGameOngoing(false)
            }
        } catch (e) {
            console.log(e);
        }
    };

    const addPlayerName = async (event) => {
        event.preventDefault();
        try {
            // API logic here
            const user = JSON.parse(localStorage.getItem("user"))
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/api/game/${gameid}/addPlayer`,
                {
                    method: "POST",
                    headers: { "Content-Type": "application/json", 
                        'jwt-token': user.token},
                    body: JSON.stringify({ player: newPlayer })
                }
            );
            const newPlayers = await response.json();
            if (newPlayers.error !== undefined) {
                alert(newPlayers.error)
            } else {
                setNewPlayer("")
                setPlayers(newPlayers);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const deletePlayerName = async (event, player) => {
        event.preventDefault();
        try {
            // API logic here
            const user = JSON.parse(localStorage.getItem("user"))
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/api/game/${gameid}/player`,
                {
                    method: "DELETE",
                    headers: { "Content-Type": "application/json",
                        'jwt-token': user.token },
                    body: JSON.stringify({ player: player })
                }
            );
            const newPlayers = await response.json();
            if (newPlayers.error !== undefined) {
                alert(newPlayers.error)
            } else {
                setPlayers(newPlayers);
            }
        } catch (e) {
            console.log(e);
        }
    };
    const checkEditSetting = (e, setting) => {
        const newSetting = e.currentTarget.textContent.trim()
        if (newSetting !== currentGame.title) {
            // Show save
            e.target.parentElement.querySelector('.saveSetting').classList.remove('disabledButton')
        } else {
            // hide save
            e.target.parentElement.querySelector('.saveSetting').classList.add('disabledButton')
        }
    }
    const saveSetting = async (e, key) => {
        e.preventDefault();
        if( e.target.parentElement.querySelector('.saveSetting').classList.contains('disabledButton') ){
            return false;
        }
        console.log(e)
        const newSetting = e.target.parentElement.querySelector('[contenteditable]').textContent.trim()
        console.log(newSetting)
        try {
            // API logic here
            var postObj = {};
            postObj[key] = newSetting;
            const user = JSON.parse(localStorage.getItem("user"))
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/api/game/${gameid}/setSetting`,
                {
                    method: "POST",
                    headers: { "Content-Type": "application/json",
                        'jwt-token': user.token },
                    body: JSON.stringify({ setting: postObj })
                }
            );
            const setSetting = await response.json();
            console.log(setSetting)
            if (setSetting.error !== undefined) {
                alert(setSetting.error)
            } else {
                getPlayers();
                e.target.parentElement.querySelector('.saveSetting').classList.add('disabledButton')
            }
        } catch (e) {
            console.log(e);
        }
    }

    return <>
        <div className="editTitle editSetting">
            <h2 onInput={(e) => { checkEditSetting(e, 'title') }} suppressContentEditableWarning={true} contentEditable>{currentGame.title}</h2>
            <button onClick={(e) => { saveSetting(e, 'title') }} className="saveSetting disabledButton">save</button>
        </div>
        <table className="newPlayerTable"><tbody>
            {players.map((player) => (
                <tr key={player}>
                    <td>{player}</td>
                    <td><button onClick={(event) => (deletePlayerName(event, player))}><span class="material-symbols-outlined">delete</span></button></td>
                </tr>
            ))}
        </tbody></table>
        {gameOngoing === false &&
            <div className="addNewPlayer editSetting">
                <input value={newPlayer} onChange={(e) => { setNewPlayer(e.target.value) }} type="text" placeholder="Player" />
                <button onClick={(event) => (addPlayerName(event))}>player <span class="material-symbols-outlined">add</span></button>
            </div>
        }
        {gameOngoing === true &&
            <div>finish the round before editing players</div>
        }

        <div className="settings">
                <label htmlFor="maxscore">Maximal score</label>
            <div className="maxscore editSetting">
                <div onInput={(e) => { checkEditSetting(e, 'maxscore') }} id="maxscore" suppressContentEditableWarning={true} contentEditable>{currentGame.maxscore}</div>
                <button onClick={(e) => { saveSetting(e, 'maxscore') }} className="saveSetting disabledButton">save</button>
            </div>
        </div>
        {/* test
        {gameid} */}
    </>
}
export default SingleGameSettings