import { React, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import _uniqueId from 'lodash/uniqueId';

const SingleGameDashboard = (props) => {
    const { gameid } = useParams(),
        [singleGame, setSingleGame] = useState([]),
        [gameTable, setGameTable] = useState({ players: [], game: [] });
    useEffect(() => {
        getGame();
        const interval = setInterval(() => getGame(), 2500);
        return () => {
            clearInterval(interval);
        };

    }, []);

    var prevdata = [];
    const getGame = async () => {
        const user = JSON.parse(localStorage.getItem("user"))
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/api/game/${gameid}`, {
                headers: {
                    'jwt-token': user.token
                }
            }
            );
            let game = await response.json();

            var singleGameObject = { players: [], game: [] };
            for (const player of game.players) {
                singleGameObject.players.push({ name: player });

                var i = 0;
                for (const rounds of game.rounds) {
                    var scoreRow = {
                        score: 0,
                        mexicans: [],
                        battles: [],
                        key: `${player}${i}`
                    }
                    for (const heat of rounds.heats) {
                        if (heat.loser == player) {
                            scoreRow.score += (2 ** heat.mexicans);
                            // console.log(i, player, scoreRow.score)
                            if (heat.mexicans !== 0) {
                                scoreRow.mexicans.push({
                                    amount: heat.mexicans,
                                    losingvalue: heat.losingvalue
                                });
                            }
                            if (heat.battles.length !== 0) {
                                scoreRow.battles = heat.battles;
                            }
                        }
                    }
                    if (singleGameObject.game[i] == undefined) {
                        singleGameObject.game[i] = [];
                    }
                    singleGameObject.game[i].push(scoreRow)
                    i++;
                }

            }
            if (JSON.stringify(singleGameObject) !== JSON.stringify(prevdata)) {
                prevdata = singleGameObject;
                setSingleGame(game);
                setGameTable(singleGameObject);
                console.log(singleGameObject)
            }
        } catch (e) {
            console.log(e);
        }
    };


    return <>
        <h2>{singleGame.title}</h2>
        <div className="statsTable">
            <table>
                <thead>
                    <tr>
                        {gameTable.players.map((player) => (
                            <th style={{width:(100/gameTable.players.length)+"%",minWidth:(parseInt(singleGame.maxscore)/5*50)+"px"}} key={player.name}>{player.name}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {gameTable.game.map((game, i) => (
                        <tr key={i}>
                            {game.map((row, nth) => (
                                <td className="scoreCell" key={row.key}>
                                    <Tally score={row.score} nth={nth} />
                                    <Battles battles={row.battles} />
                                    <Mexicans mexicans={row.mexicans} />
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    </>
}
export default SingleGameDashboard

function Tally(props) {
    const { score } = useParams();
    var linesObj = [];
    for (let i = 0; i < props.score; i++) {
        var tclass = "",
            rot = (Math.floor(Math.random() * 500) / 50) - 5;
        if (Math.round((i + 1) / 5) === (i + 1) / 5) {
            rot = 55 + rot;
            tclass = "crossed";
        }
        linesObj.push({ key: _uniqueId(""), deg: rot, tclass: tclass, nth: `${props.nth}-${i}` })
    }
    return (
        <>
            {linesObj.map((line) => (
                <span data-nth={line.nth} key={line.key} className={line.tclass + " tallyLine"} style={{ transform: 'rotate(' + line.deg + 'deg)' }}></span>
            ))}
        </>
    )
}

function Mexicans(props) {
    var newMexicans = [];
    for (const mexican of props.mexicans) {
        var singleMexican = { hats: [] };
        for (let i = 0; i < parseInt(mexican.amount); i++) {
            singleMexican.hats.push({ offset: i * 7, rotate: (Math.floor(Math.random() * 500) / 20) - 12.5 });
        }
        singleMexican.lowscore = mexican.losingvalue;
        newMexicans.push(singleMexican)
    }
    return (
        <>
            {newMexicans.map((mexican, i) => (
                <div key={i} className="sombrero">
                    {mexican.hats.map((hat, j) => (
                        <img style={{ marginTop: '-' + hat.offset + 'px', transform: 'rotate(' + hat.rotate + 'deg)' }} className="sombreroImg" key={i + "" + j} src="/icons/hat.svg" />
                    ))}
                    {mexican.lowscore !== 0 &&
                        <span>{mexican.lowscore}</span>
                    }
                </div>
            ))}
        </>
    )
}
function Battles(props) {
    if (props.battles.length !== 0) { console.log(props.battles) }
    return (<>
        {props.battles.length !== 0 &&
            <div className="battleSetHolder">
            <div className={props.battles.length>1?"battleSet boxed":"battleSet"}>
                {props.battles.map((battle, i) => (
                    <div key={i} className="battleIcon">
                        <img src={"/icons/swords-"+battle.players.length+".svg"}/>
                    </div>
                ))}
            </div>
            </div>
        }
    </>)
}