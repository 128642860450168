import {React} from "react";

const SingleGameNavigation = (props)=>{
    const {gameid,page} = props;

    return <ul className="gamesSubNavigation">
        <a className={page=="settings" ? "set" : ""} href={`/games/${gameid}/settings`}><li>Settings</li></a>
        <a className={page=="playing" ? "set" : ""} href={`/games/${gameid}`}><li>Play</li></a>
        <a className={page=="dashboard" ? "set" : ""} href={`/games/${gameid}/dashboard`}><li>Score</li></a>
    </ul>
}
export default SingleGameNavigation;